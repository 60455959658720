<template>
    <OModal ref="downloadDocsModal" name="downloadDocsModal" @show="onShow">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!props.downloadFromDetails">{{$t('Download Documents')}}</h5>
                    <h5 class="modal-title" v-if="props.downloadFromDetails">{{$t('Download Document')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="!props.downloadFromDetails">
                        <span style="font-size:small;font-weight:500">{{$t('Rows selected for download: ')}} <span v-if="vLoading" class="spinner-border spinner-border-sm"></span><span v-else>{{selectedIds.length}}</span></span>
                    </div>
                    <div class="mt-2">
                        <span style="font-size:small;">{{$t('Please choose a file naming convention for your download:')}}</span>
                    </div>
                    <select class="form-control" v-model="vSelectedNamingConvention" style="max-width:350px" >
                        <option value="filename">{{$t('Filename')}}</option>
                        <option v-for="row in dsSyntaxes.data" :value="'custom_' + row.ID">{{row.Title}}</option>
                    </select>

                    <div class="form-check mt-1">
                        <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" v-model="inclRelatedDocs">
                            {{$t('Include Related Documents')}}
                        </label>
                        <div class="form-check ms-2" v-if="inclRelatedDocs">
                            <label class="form-check-label" :title="$t('Include documents that are indirectly related (related to a related document at any level)')">
                            <input class="form-check-input" type="checkbox" v-model="inclRelatedDocsIndirect">
                                {{$t('Indirect Relations')}}
                            </label>
                        </div>
                    </div>

                    <div class="form-check">
                        <label class="form-check-label" :title="$t('Check to ensure that the selected documents and its attachments are stored in separate folders.') + '\n' + $t('Folder name will follow the selected file naming convention.')">
                        <input class="form-check-input" type="checkbox" v-model="separateFolders">
                            {{$t('Separate Folders')}}
                        </label>
                        <!-- <i class="bi bi-info-circle ms-2" :title="$t('Check to ensure that the selected documents and its attachments are stored in separate folders.') + '\n' + $t('Folder name will follow the selected file naming convention.')"></i> -->
                    </div>

                    <div class="mt-3">
                        <span style="font-size:small;">{{$t('Choose file types to include:')}}</span>
                    </div>
                    <div class="form-check mt-1">
                        <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" v-model="vIncludeMainFile">
                            {{$t('Native File')}} 
                            <div v-if="vIncludeMainFile">
                                <div v-if="emptyFileRefCount" class="ms-2" style="font-size:small;"><i class="bi bi-exclamation-triangle text-warning"></i> <span>{{ `${$t('Native file missing for')} ${emptyFileRefCount} ${$t('rows')}` }}</span></div>
                                <div v-if="noMainFileDLAccessCount" class="ms-2" style="font-size:small;"><i class="bi bi-exclamation-triangle text-warning"></i> <span>{{ `${$t('Missing permission to download native file in')} ${noMainFileDLAccessCount} ${$t('rows')}` }}</span></div>
                            </div>
                        </label> 
                    </div> 
                    <div class="form-check">
                        <label class="form-check-label">
                        <input class="form-check-input include-rendition" type="checkbox" v-model="vIncludeRendition">
                            {{$t('PDF Rendition')}}
                        </label>
                    </div>
                    <div class="form-check">
                        <label class="form-check-label">
                        <input class="form-check-input include-attachments" type="checkbox" v-model="vIncludeAttachments">
                            {{$t('Attachments')}} <span v-if="vIncludeAttachments" class="ms-2" style="font-size:small;">{{ $t('No selection = all types') }}</span>
                        </label>
                        <div v-if="vIncludeAttachments">
                            <div class="form-check-inline ms-2" v-for="row in dsAttachmentTypes.data">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" :checked="attachmentTypesSelected.indexOf(row.Type) > -1" @click="attachmentTypeSelected(row.Type)">
                                    {{ row.Type }}
                                </label>
                            </div>
                        </div>
                    </div> 
                
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="downloadDocuments" :disabled="vWorking || !selectedIds.length || !fileTypesSelected.length">
                        <span v-if="vWorking" class="spinner-border spinner-border-sm me-1"></span>{{$t('Download')}}
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, computed, watch } from 'vue';
    import { getOrCreateProcedure } from 'o365-modules'
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { context } from 'o365-modules';
    import { alert } from 'o365-vue-services';

    const props = defineProps({
        dataObject: Object,
        selectedDocIDs: Object, //Array with doc ids that you want to download instead of DO
        isDFO: {
            type: Boolean, 
            default: false
        },
        downloadFromDetails: {
            type: Boolean,
            default: false
        }
    })

    const dsSyntaxesDef = {
        id: 'dsSyntaxes',
        viewName: "aviw_Arena_FileNameSyntaxesLookup",
        appId: props.dataObject.appId,
        maxRecords: 500,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "ID", type: "number" },
                {name: "Title", type: "string", sortOrder: 1, sortDirection: "asc"}
            ]
    }

    const downloadDocsModal = ref(null), vSelectedNamingConvention = ref("filename"), vIncludeMainFile = ref(true), vIncludeAttachments = ref(false), vIncludeRendition = ref(false), vWorking = ref(false),
        vLoading = ref(false), selectedIds = ref([]), noMainFileDLAccessCount = ref(null), emptyFileRefCount = ref(null), attachmentTypesSelected = ref([]), 
            separateFolders = ref(false), inclRelatedDocs = ref(false), inclRelatedDocsIndirect = ref(true);

    const dsSyntaxes = getOrCreateDataObject(dsSyntaxesDef);
    const dsAttachmentTypes = getOrCreateDataObject({
        id: 'dsAttachmentTypes',
        viewName: "aviw_Arena_AttachmentTypes",
        appId: props.dataObject.appId,
        maxRecords: -1,
        fields:
            [{name: "Type", type: "string", sortOrder: 1, sortDirection: "asc"}]
    });

    const procDocsSelected = getOrCreateProcedure({
        id: 'procDocsSelected',
        procedureName: 'astp_Arena_DocumentsSelected'
    });
    const procReqsSelected = getOrCreateProcedure({
        id: 'procReqsSelected',
        procedureName: 'astp_Arena_ObjectsDocRequirementsSelected'
    });
    const procCheckSize = getOrCreateProcedure({
        id: 'procCheckSize',
        procedureName: 'astp_Arena_DocumentsDownloadSelectedCheckSizeCTP'
    });
    const procMarkAsViewed = getOrCreateProcedure({
        id: 'procMarkAsViewed',
        procedureName: 'astp_Arena_MarkDocumentAsViewed'
    });

    const procGetSelectionSpecifics = new getOrCreateProcedure({
        id: "procGetSelectionSpecifics", 
        procedureName: "astp_Arena_DownloadDocumentsDialog" 
    });

    const onShow = async () => {
        if (!dsAttachmentTypes.state.isLoaded){
            dsAttachmentTypes.load();
        }

        selectedIds.value = [];
        if (props.downloadFromDetails){
            selectedIds.value = [[props.dataObject.current.ID]];
        } else {
            if(props.selectedDocIDs && props.selectedDocIDs.length > 0){
                selectedIds.value = props.selectedDocIDs;
            }
            else{
                vLoading.value = true;
                await props.dataObject.selectionControl.getSelectedRows({fields:[{name:"ID"}]}).then(rows => {
                    selectedIds.value = rows.map(r => [r.ID]);
                });
                vLoading.value = false;
            }
        }
        if (selectedIds.value.length > 0) {
            getSelectionSpecifics();
        }

        dsSyntaxes.recordSource.whereClause = "'"+ context.idPath+ "' LIKE IdPath"
        await dsSyntaxes.load();
    }

    const getSelectionSpecifics = async () =>{
        const response = await procGetSelectionSpecifics.execute({ Selections: selectedIds.value, isDFO: props.isDFO, Options: inclRelatedDocs.value ? (inclRelatedDocsIndirect.value ? "InclRelatedIndirect" : "InclRelated") : null });
        noMainFileDLAccessCount.value = response?.Table?.[0]?.NoMainFileDLAccessCount || 0;
        emptyFileRefCount.value = response?.Table?.[0]?.EmptyFileRefCount || 0;
    }

    watch([inclRelatedDocs, inclRelatedDocsIndirect], ()=>{
        if (selectedIds.value.length > 0) {
            getSelectionSpecifics();
        }
    });

    // TODO: warning/confirm if obsolete/deleted
    const downloadDocuments = async() => {
        // as of 28.06.24
        // console.log(selectedIds.value.length) // 213 (expected)
        // console.log(props.dataObject.selectionControl.selectedRows.length) // 50
        // console.log(props.dataObject.data.filter(row => row.isSelected).length) // 50

        vWorking.value = true;
        if (!props.isDFO){
            await procDocsSelected.execute({Values:selectedIds.value});
        } else {
            await procReqsSelected.execute({Values:selectedIds.value});
        }
        //TODO: logUserAsViewed
        procCheckSize.execute({
            FileTypes:fileTypesSelected.value.toString(), AttachmentTypes:attachmentTypesSelected.value.length ? '&attchtypes='+attachmentTypesSelected.value.toString() : '', isDFO: props.isDFO, 
            Options: inclRelatedDocs.value ? (inclRelatedDocsIndirect.value ? "InclRelatedIndirect" : "InclRelated") : null
        }).then((res)=>{
            if (res.Table[0].Ok){
                let queryParams = new URLSearchParams({
                    name: vSelectedNamingConvention.value,
                    filetypes: fileTypesSelected.value.toString(),
                });

                if (attachmentTypesSelected.value.length) {
                    queryParams.append('attchtypes', attachmentTypesSelected.value.toString());
                }

                if (props.isDFO) {
                    queryParams.append('dfo', 'true');
                }

                if(separateFolders.value){
                    queryParams.append('separateFolders', 'true');
                }
                if(inclRelatedDocs.value){
                    queryParams.append('options', inclRelatedDocsIndirect.value ? "InclRelatedIndirect" : "InclRelated");
                }

                window.open('/api/arena/downloadselectedfiles?'+queryParams);
            } else {
                let vAlert = $t("The combined size of the files you tried to download") + " (" + res.Table[0].SumFileSizeMB + " ) " + $t("exceeds the maximum allowed size of")+" " + res.Table[0].MaxDownloadSizeMB + ". " + $t("Please try again with a smaller batch of documents") ;
                alert(vAlert,'warning',{autohide:true, delay: 3 * 1000});
            }

            vWorking.value = false;
        });

        downloadDocsModal.value.hide();
    }

    const fileTypesSelected = computed(()=>{
        let fileTypes = []
        if (vIncludeMainFile.value){
            fileTypes.push("main")
        }
        if (vIncludeRendition.value){
            fileTypes.push("rendition")
        }
        if (vIncludeAttachments.value){
            fileTypes.push("attachment")
        }
        return fileTypes
    })

    const attachmentTypeSelected = (pType)=>{
        let index = attachmentTypesSelected.value.indexOf(pType);
        if (index > -1){
            attachmentTypesSelected.value.splice(index, 1);
        } else {
            attachmentTypesSelected.value.push(pType);
        }
    }

    defineExpose({ show: () => downloadDocsModal.value?.show() })
</script>